.header {
    height: 100vh;
    display: flex;
    flex-direction: column;
    flex-justify: center;
}

.header .content {
    position: relative;
    top: 35%;
    padding: 3em;

    background-color: #192639;
}

.header a {
    color: white;
}

.sideText {
    color: #47ebd5
}

.header h1 {
    margin-bottom: 0;
}

.aocwidget-container {
    border-radius: 4px;
    border: 1px solid #009900;
}