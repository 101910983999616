.conway-canvas {
    position: sticky;
    top: 0;
    z-index: -1;

    width: 100vw;
    height: 100vh;
    overflow: hidden;

    background-color: black;
}

.photoepilepsy-warning {
    position: fixed;
    top: 0;
    z-index: 100;
    text-align: center;

    display: flex;
    flex-direction: column;
    justify-content: center;

    width: 100%;
    height: 100%;

    padding: 1.5em;
    background-color: rgba(27, 32, 33, 0.97);
    color: #FAFAFF;
}

.photoepilepsy-warning h2 {
    color: #5998C5;
    margin: 0;
    margin-top: 0.5em;
    margin-bottom: 0.5em;
}