#about h2 {
    padding-top: 0.5em;
}

#about .content b,a {
    color: #47ebd5;
}

#about .content .textContainer {
    color: white;
    background-color: rgba(27, 32, 33, 0.95);
    border-radius: var(--bs-border-radius) !important;
    padding: 1em;
    margin-bottom: 1em;
}