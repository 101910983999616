.contained {
  scroll-behavior: smooth;

  top: 0;
  bottom: 0;
  position: fixed;
  overflow-y: scroll;
  overflow-x: hidden;

  padding-bottom: 3em;

  width: 100%;

  color: #FAFAFF;

  scrollbar-color: #5998C5 #242424;
  scrollbar-width: thin;

  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace
}

.accordion {
  background-color: rgba(0, 0, 0, 0);
  border: none;
}

.accordion-item {
  background-color: rgba(0, 0, 0, 0);
  border: none;
}

.accordion-button {
  color: white;
  background-color: rgba(27, 32, 33, 0.95);
  border-radius: var(--bs-border-radius) !important;
  padding: 1em;
  margin-bottom: 1em;
  border: none;
  box-shadow: none;
}

.accordion-button::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e"); 
}

.accordion-button h2 {
  margin: 0;
}

.accordion-button:focus {
  box-shadow: none;
}

.accordion-body {
  color: white;
  background-color: rgba(27, 32, 33, 0.95);
  border-radius: var(--bs-border-radius) !important;
  margin-bottom: 1em;
}


.accordion-button:not(.collapsed) {
  background-color: #192639;
  color: #47ebd5;
  box-shadow: none;
}