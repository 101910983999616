#contact {
    margin-top: 2em;
}

#contact .content b,a {
    color: #47ebd5;
}

#contact .content .textContainer {
    color: white;
    background-color: rgba(27, 32, 33, 0.95);
    border-radius: var(--bs-border-radius) !important;
    padding: 1em;
}

#contact input {
    background-color: #2e3638;
    border: none;
    caret-color: #47ebd5;
    border-bottom: 2px solid white;
    padding-left: 5px;
    color: white;
    width: 3em;
}

#contact input:focus {
    outline:none;
}

#revealEmail {
    text-decoration: underline;
    cursor: pointer;
}