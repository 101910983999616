#projects .content {

}

#projects .project-title {
    position: absolute;
    bottom: 0;
    width: 100%;
    text-align: right;
    z-index: 5;

    padding: 0em 0.3em;

    background-image: linear-gradient(rgba(0, 0, 0, 0.7), black);
    font-size: 1.5em;
    font-weight: semibold;
    border-bottom-radius: var(--bs-border-radius) !important;
}

#projects .project-card {
    cursor: pointer;

    position: relative;
    color: white;
    background-color: rgba(27, 32, 33, 0.95);
    border-radius: var(--bs-border-radius) !important;
    transition: transform .25s;
    height: 100%;
}

#projects .row {
    margin-bottom: 1em;
}

#projects .project-card:hover {
    transform: scale(1.03)
}

#projects .project-image {
    width: 100%;
    object-fit: cover
}

.modal-content {
    background-color: rgba(27, 32, 33, 0.95);
    color: white;
}

.modal-title {
    font-size: 2.2rem;
}

.btn-close {
	box-sizing: content-box;
	width: 1em;
	height: 1em;
	padding: .25em .25em;
	color: #000;
	background: transparent url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23FFF%27%3e%3cpath d=%27M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z%27/%3e%3c/svg%3e") center/1em auto no-repeat;
	border: 0;
	border-radius: .375rem;
	opacity: .5;
}

.tags {
    display: flex;
    gap: 0.5em;
    margin-top: 1em;
}